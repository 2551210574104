import { useQuery } from '@tanstack/react-query'
import { OrganizationUserNotificationPreferencesEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetUserOrganizationPreferences(organizationId: string): {
  organizationUserNotificationPreferences: OrganizationUserNotificationPreferencesEntity | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: organizationUserNotificationPreferences,
    isLoading,
    isError,
    refetch,
  } = useQuery(['userOrganizationCommunicationPreferences', organizationId], async () => {
    return qoursesApi.notification.notificationControllerGetOrganizationUserNotificationPreferences(
      organizationId,
    )
  })

  return { organizationUserNotificationPreferences, isLoading, isError, refetch }
}
