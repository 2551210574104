/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostPlatformSubscribeDto } from '../models/PostPlatformSubscribeDto';
import type { StripeLinkDto } from '../models/StripeLinkDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentPlatformService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create a checkout session for a platform subscription
     * @param requestBody
     * @returns StripeLinkDto The checkout session has been created
     * @throws ApiError
     */
    public paymentPlatformControllerGetSubscribeDetails(
        requestBody: PostPlatformSubscribeDto,
    ): CancelablePromise<StripeLinkDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/payment/platform/subscribe',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create an Organization billing portal link
     * @returns StripeLinkDto The billing portal link has been created
     * @throws ApiError
     */
    public paymentPlatformControllerGetOrganizationBillingPortalDetails(): CancelablePromise<StripeLinkDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payment/platform/manage',
        });
    }
}
