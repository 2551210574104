import { useQuery } from '@tanstack/react-query'
import { UserCommunicationPreferencesEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetUserCommunicationPreferences(): {
  userCommunicationPreferences: UserCommunicationPreferencesEntity | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: userCommunicationPreferences,
    isLoading,
    isError,
    refetch,
  } = useQuery(['userCommunicationPreferences'], async () => {
    return qoursesApi.notification.notificationControllerGetUserCommunicationPreferences()
  })

  return { userCommunicationPreferences, isLoading, isError, refetch }
}
