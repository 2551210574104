import { Button } from '@/shadcn/components/ui/button.tsx'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/shadcn/components/ui/collapsible.tsx'
import { useAuth0 } from '@auth0/auth0-react'
import { Switch } from '@headlessui/react'
import {
  ChatBubbleBottomCenterTextIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline'
import { AnimatePresence, motion } from 'framer-motion'
import { ChevronDown, ChevronUp, LogOut } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { qoursesApi } from '../api/qourses.tsx'
import LoadingSpinner from '../components/LoadingSpinner.tsx'
import PageHeader from '../components/PageHeader.tsx'
import useGetUserCommunicationPreferences from '../hooks/notifications/useGetUserCommunicationPreferences.tsx'
import useGetUserNotificationPreferences from '../hooks/notifications/useGetUserNotificationPreferences.tsx'
import useGetUserOrganizationPreferences from '../hooks/notifications/useGetUserOrganizationPreferences.tsx'
import useOrganization from '../hooks/organization/useOrganization.tsx'
import { organizationStore } from '../hydration/organization.tsx'
import {
  ToastVariant,
  classNames,
  minDelay,
  sendGenericErrorNotification,
  sendNotification,
} from '../utils.tsx'
import { Title } from './courses/Title.tsx'
import { ResizablePanel } from './courses/components/Calendar.tsx'

export default function UserSettings() {
  const { logout } = useAuth0()

  const { t: translate } = useTranslation()

  const pageHeaderTitle = translate('pages.personal-settings.pageHeaderTitle')

  const { allOrganizations, isFetchingOrganizations } = useOrganization()

  const handleLogout = () => {
    organizationStore.persist.clearStorage()
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return (
    <>
      <PageHeader title={pageHeaderTitle} subtitle={translate('breadcrumbs.UserSettings')} />
      <ResizablePanel>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-12 md:grid-cols-3">
          <Title
            title={translate('pages.personal-settings.communication.title')}
            subtitle={translate('pages.personal-settings.communication.subtitle')}
            icon={<ChatBubbleLeftRightIcon />}
            complete={true}
            hideCompletion={true}
          />
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <CommunicationPreferences />
          </div>
          <Title
            title={translate('pages.personal-settings.notification.title')}
            subtitle={translate('pages.personal-settings.notification.subtitle')}
            icon={<ChatBubbleBottomCenterTextIcon />}
            complete={true}
            hideCompletion={true}
          />
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <NotificationPreferences />
            {!isFetchingOrganizations &&
              allOrganizations &&
              allOrganizations.map((organization) => (
                <OrganizationNotificationPreferences
                  key={organization.id}
                  organizationId={organization.id}
                  organizationName={organization.name}
                />
              ))}
          </div>
        </div>
        <div className="flex justify-end">
          <Button variant="indigo" onClick={handleLogout}>
            Logout
            <LogOut className="-mr-0.5 ml-2 h-5 w-5" aria-hidden="true" />
          </Button>
        </div>
      </ResizablePanel>
    </>
  )
}

function CommunicationPreferences() {
  const { t: translate } = useTranslation()

  const { userCommunicationPreferences, isLoading, isError, refetch } =
    useGetUserCommunicationPreferences()

  const [isLoadingSwitchEmail, setIsLoadingSwitchEmail] = useState(false)
  const [isLoadingSwitchApn, setIsLoadingSwitchApn] = useState(false)

  if (isLoading)
    return (
      <div className="col-span-full gap-y-5">
        <Skeleton className="h-20 w-full" />
        <Skeleton className="mt-4 h-20 w-full" />
      </div>
    )

  if (isError) return <div>error</div>

  if (!userCommunicationPreferences) {
    return null
  }

  const handleSwitchEmail = async () => {
    try {
      setIsLoadingSwitchEmail(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateUserCommunicationPreferences({
          emailEnabled: !userCommunicationPreferences.emailEnabled,
        }),
        1000,
      )
      sendNotification(
        translate('pages.personal-settings.communication.email.notification.title'),
        translate('pages.personal-settings.communication.email.notification.subtitle', {
          context: !userCommunicationPreferences.emailEnabled ? 'active' : 'inactive',
        }),
        ToastVariant.Success,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification()
    } finally {
      setIsLoadingSwitchEmail(false)
    }
  }

  const handleSwitchApn = async () => {
    try {
      setIsLoadingSwitchApn(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateUserCommunicationPreferences({
          apnEnabled: !userCommunicationPreferences.apnEnabled,
        }),
        1000,
      )
      sendNotification(
        translate('pages.personal-settings.communication.apn.notification.title'),
        translate('pages.personal-settings.communication.apn.notification.subtitle', {
          context: !userCommunicationPreferences.apnEnabled ? 'active' : 'inactive',
        }),
        ToastVariant.Success,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification()
    } finally {
      setIsLoadingSwitchApn(false)
    }
  }

  return (
    <>
      <div className="col-span-full">
        <SwitchWithLabels
          title={translate('pages.personal-settings.communication.email.title')}
          subtitle={translate('pages.personal-settings.communication.email.subtitle')}
          enabled={userCommunicationPreferences.emailEnabled}
          disabled={false}
          onChange={handleSwitchEmail}
          loading={isLoadingSwitchEmail}
        />
      </div>
      <div className="col-span-full">
        <SwitchWithLabels
          title={translate('pages.personal-settings.communication.apn.title')}
          subtitle={translate('pages.personal-settings.communication.apn.subtitle')}
          enabled={userCommunicationPreferences.apnEnabled}
          disabled={false}
          onChange={handleSwitchApn}
          loading={isLoadingSwitchApn}
        />
      </div>
    </>
  )
}

function NotificationPreferences() {
  const { t: translate } = useTranslation()

  const { userNotificationPreferences, isLoading, isError, refetch } =
    useGetUserNotificationPreferences()

  const [loadingSwitchBookingNotification, setLoadingSwitchBookingNotification] = useState(false)

  if (isLoading)
    return (
      <div className="col-span-full gap-y-5">
        <Skeleton className="h-20 w-full" />
      </div>
    )

  if (isError) return <div>error</div>

  if (!userNotificationPreferences) {
    return null
  }

  const handleSwitchProductUpdates = async () => {
    try {
      setLoadingSwitchBookingNotification(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateUserNotificationPreferences({
          productUpdatesNotifications: !userNotificationPreferences.productUpdatesNotifications,
        }),
        1000,
      )
      sendNotification(
        translate('pages.personal-settings.notification.product-updates.notification.title'),
        translate('pages.personal-settings.notification.product-updates.notification.subtitle', {
          context: !userNotificationPreferences.productUpdatesNotifications ? 'active' : 'inactive',
        }),
        ToastVariant.Success,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification()
    } finally {
      setLoadingSwitchBookingNotification(false)
    }
  }

  return (
    <div className="col-span-full">
      <SwitchWithLabels
        title={translate('pages.personal-settings.notification.product-updates.title')}
        subtitle={translate('pages.personal-settings.notification.product-updates.subtitle')}
        enabled={userNotificationPreferences.productUpdatesNotifications}
        disabled={false}
        onChange={handleSwitchProductUpdates}
        loading={loadingSwitchBookingNotification}
      />
    </div>
  )
}

function OrganizationNotificationPreferences(props: {
  organizationId: string
  organizationName: string
}) {
  const { organizationUserNotificationPreferences, isLoading, isError, refetch } =
    useGetUserOrganizationPreferences(props.organizationId)

  const { t: translate } = useTranslation()

  const [openOrganizationNotificationPreferences, setOpenOrganizationNotificationPreferences] =
    useState(false)

  const [isLoadingRenamedNotifications, setIsLoadingRenamedNotifications] = useState(false)

  const [isLoadingUserAddedNotifications, setIsLoadingUserAddedNotifications] = useState(false)

  if (isLoading) {
    return <div>{translate('common.loading')}</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  if (!organizationUserNotificationPreferences) {
    return null
  }

  const handleSwitchOrganizationRenamedNotifications = async () => {
    try {
      setIsLoadingRenamedNotifications(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateOrganizationUserNotificationPreferences(
          props.organizationId,
          {
            organizationRenamedNotifications:
              !organizationUserNotificationPreferences.organizationRenamedNotifications,
          },
        ),
        1000,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification()
    } finally {
      setIsLoadingRenamedNotifications(false)
    }
  }

  const handleSwitchUserAddedNotifications = async () => {
    try {
      setIsLoadingUserAddedNotifications(true)
      await minDelay(
        qoursesApi.notification.notificationControllerUpdateOrganizationUserNotificationPreferences(
          props.organizationId,
          {
            userAddedToOrganizationNotifications:
              !organizationUserNotificationPreferences.userAddedToOrganizationNotifications,
          },
        ),
        1000,
      )
      refetch()
    } catch (e) {
      sendGenericErrorNotification()
    } finally {
      setIsLoadingUserAddedNotifications(false)
    }
  }

  return (
    <div className="col-span-full">
      <Collapsible
        open={openOrganizationNotificationPreferences}
        onOpenChange={setOpenOrganizationNotificationPreferences}
        className="col-span-full space-y-2 rounded-lg bg-white p-5 ring-1 ring-gray-200"
      >
        <div className="flex items-center justify-between space-x-4">
          <div className="flex flex-col">
            <h4 className="text-md font-medium">{props.organizationName}</h4>
            <p className="text-xs text-gray-600">
              {translate('pages.personal-settings.notification.organization.title')}
            </p>
          </div>
          <CollapsibleTrigger asChild>
            <Button variant="outline" className="w-9 bg-white p-0">
              {!openOrganizationNotificationPreferences ? (
                <ChevronDown className="h-4 w-4" />
              ) : (
                <ChevronUp className="h-4 w-4" />
              )}
            </Button>
          </CollapsibleTrigger>
        </div>
        <CollapsibleContent className="space-y-2 pt-4">
          <div className="mb-4">
            <SwitchWithLabels
              title={translate('pages.personal-settings.notification.organization.userAdded.title')}
              subtitle={translate(
                'pages.personal-settings.notification.organization.userAdded.subtitle',
              )}
              enabled={organizationUserNotificationPreferences.userAddedToOrganizationNotifications}
              disabled={false}
              onChange={handleSwitchUserAddedNotifications}
              loading={isLoadingUserAddedNotifications}
            />
          </div>
          <div className="mb-4">
            <SwitchWithLabels
              title={translate(
                'pages.personal-settings.notification.organization.organizationRenamed.title',
              )}
              subtitle={translate(
                'pages.personal-settings.notification.organization.organizationRenamed.subtitle',
              )}
              enabled={organizationUserNotificationPreferences.organizationRenamedNotifications}
              disabled={false}
              onChange={handleSwitchOrganizationRenamedNotifications}
              loading={isLoadingRenamedNotifications}
            />
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  )
}

export function SwitchWithLabels(props: {
  title: string | React.ReactNode
  subtitle: string
  enabled: boolean
  disabled: boolean
  loading: boolean
  onChange: () => void
}) {
  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
          {props.title}
        </Switch.Label>
        <Switch.Description as="span" className="w-[90%] text-sm text-gray-500">
          {props.subtitle}
        </Switch.Description>
      </span>
      <Switch
        disabled={props.disabled}
        checked={props.enabled}
        onChange={props.onChange}
        className={classNames(
          props.enabled ? 'bg-indigo-600 disabled:bg-gray-600' : 'bg-gray-200 disabled:bg-gray-600',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out disabled:cursor-not-allowed disabled:opacity-50',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            props.enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        >
          <AnimatePresence>
            {props.loading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="h-5 w-5 border-2 border-transparent"
              >
                <LoadingSpinner className="" />
              </motion.div>
            )}
          </AnimatePresence>
        </span>
      </Switch>
    </Switch.Group>
  )
}
