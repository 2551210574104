import GlobalErrorPage from '@/pages/GlobalErrorPage.tsx'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { Suspense } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Outlet, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar.tsx'
import { qoursesApi, setActiveApiOrganization } from './api/qourses.tsx'
import PageHeader from './components/PageHeader.tsx'
import { organizationStore } from './hydration/organization.tsx'
import LoadingLogo from './pages/LoadingLogo.tsx'

export const APP_METADATA_CLAIM_NAMESPACE = 'https://api.qours.es/app_metadata'

export default function SidebarLayoutOutlet() {
  const { isLoading: isAuthenticationPending, isAuthenticated, user } = useAuth0()

  const navigate = useNavigate()

  const hydratedOrganization = organizationStore((state) => state)

  // This query is used to hydrate the organization store
  // This either results in:
  // 1. The user hydrating their org from local storage
  // 2. Us setting the current organization from a selection from their token
  // 3. Setting the organization to undefined if the user has no org in their token
  const organization = useQuery(
    ['activeOrganization'],
    async () => {
      try {
        // The user has no organization in his storage -> we hydrate it from the token
        if (!hydratedOrganization || !hydratedOrganization.name) {
          if (user) {
            const organizationsInToken = user[APP_METADATA_CLAIM_NAMESPACE]?.organizations

            // The user is fully new (never created an organization or was part of one)
            if (!organizationsInToken) {
              return {}
            }

            // If the user has no organization currently selected, we select the first one in their token
            // TODO: WE NEED TO NOT USE THE FIRST ONE IN THE TOKEN, BUT THE FIRST ONE COMING BACK FROM THE
            // ALL ORGANIZATIONS API CALL! THIS CURRENTLY BREAKS IF THE ORGANIZATION DOES NOT EXIST IN THE BACKEND
            // AND HARD LOCKS THE USER

            const allOrganizations =
              await qoursesApi.organization.organizationControllerGetOrganizationsForUser()
            const firstOrganization = allOrganizations[0].slug

            // The user has no organization in his token -> we set the organization to an empty object
            if (!firstOrganization) {
              return {}
            }

            // We set the organization in the API client
            setActiveApiOrganization(firstOrganization)

            //TODO: Handle what happens when we cant fetch this
            const organization =
              await qoursesApi.organization.organizationControllerGetOrganizationBySlug()

            // Dehydrate the org to local storage
            hydratedOrganization.updateActiveOrganization(organization)
            return organization
          }
          // The user has an organization in his storage -> we just read it from storage
        } else {
          const allOrganizations =
            await qoursesApi.organization.organizationControllerGetOrganizationsForUser()

          // Select the organization that's currently hydrated from the api response
          const hydratedAndUpdatedOrganization = allOrganizations.find(
            (org) => org.slug === hydratedOrganization.slug,
          )

          setActiveApiOrganization(hydratedAndUpdatedOrganization.slug)
          hydratedOrganization.updateActiveOrganization(hydratedAndUpdatedOrganization)
          return hydratedAndUpdatedOrganization
        }
      } catch (e) {
        console.error(e)
        return { isError: true }
      }
    },
    {
      enabled: !!user,
    },
  )

  const apiOrganization = useQuery(
    ['organizations'],
    async () => {
      return qoursesApi.organization.organizationControllerGetOrganizationsForUser()
    },
    {
      enabled: !!user,
    },
  )

  // TODO: handle if the user has no internet connection
  // If this ain't working we got some server issues
  if (apiOrganization.isError || organization.isError) {
    return <GlobalErrorPage />
  }

  if (
    isAuthenticationPending ||
    organization.isLoading ||
    !isAuthenticated ||
    apiOrganization.isLoading
  ) {
    return <LoadingLogo />
  }

  if (
    !user[APP_METADATA_CLAIM_NAMESPACE]?.organizations ||
    Object.keys(user[APP_METADATA_CLAIM_NAMESPACE]?.organizations)?.length === 0
  ) {
    navigate('/me')
  }

  return (
    <Sidebar>
      {/*All child routes components will be rendered here without a loading screen because we are in the sidebared view*/}
      <Suspense fallback={<SuspenseSidebarLoader />}>
        <Outlet />
      </Suspense>
    </Sidebar>
  )
}

function SuspenseSidebarLoader() {
  // Assume we always kinda render the same looking
  return (
    <>
      <PageHeader
        title={' '}
        loading={true}
        titleComponent={
          <span className="inline-flex items-center gap-x-1.5 rounded-full bg-gray-100 px-2 py-1 text-sm font-medium text-gray-600 ring-1 ring-gray-200">
            <svg className="h-1.5 w-1.5 fill-gray-400" viewBox="0 0 6 6" aria-hidden="true">
              <circle cx={3} cy={3} r={3} />
            </svg>
            <Skeleton width={150} />
          </span>
        }
      />
      <Skeleton className={'h-40 w-full'} />
    </>
  )
}
