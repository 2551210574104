/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostPlatformSubscribeDto = {
    organizationPlan: PostPlatformSubscribeDto.organizationPlan;
    planInterval: PostPlatformSubscribeDto.planInterval;
};
export namespace PostPlatformSubscribeDto {
    export enum organizationPlan {
        MINI = 'MINI',
        CORE = 'CORE',
        PRO = 'PRO',
        ULTRA = 'ULTRA',
    }
    export enum planInterval {
        MONTHLY = 'MONTHLY',
        YEARLY = 'YEARLY',
    }
}

