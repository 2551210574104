import * as Sentry from '@sentry/react'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteError } from 'react-router-dom'

interface ErrorWithMessage {
  message: string
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  )
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    return new Error(String(maybeError))
  }
}

export default function GlobalErrorPage() {
  const { t: translate } = useTranslation()
  const error = useRouteError()

  const DYNAMIC_IMPORT_ERROR_PATTERNS = [
    'Failed to fetch dynamically imported module',
    'Importing a module script failed',
    'error loading dynamically imported module',
    'Unexpected token',
    'unexpected token',
    'Loading chunk',
    'Cannot find module',
    "got '<'",
  ] as const

  const isChunkLoadError = (error: unknown): boolean => {
    const normalizedError = toErrorWithMessage(error)
    return DYNAMIC_IMPORT_ERROR_PATTERNS.some((pattern) =>
      normalizedError.message.toLowerCase().includes(pattern.toLowerCase()),
    )
  }

  useEffect(() => {
    if (error && !isChunkLoadError(error)) {
      console.error('Caught Error via Global Error Page', error)
      Sentry.captureException(error)
    }
  }, [error])

  // Handle stale chunk error by reloading
  if (isChunkLoadError(error)) {
    window.location.reload()
    return null
  }

  // Status URL construction
  const getStatusURL = () => {
    const apiURL = import.meta.env.VITE_API_URL
    return apiURL.includes('api') ? apiURL.replace('api', 'status') : 'https://status.qourses.space'
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
    >
      <div className="flex flex-col items-center text-center">
        <p className="text-3xl font-semibold text-indigo-600">🔥🔥🔥🚿🧑‍🚒 {''} 🚒🚒</p>
        <h1 className="mt-4 text-xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          {translate('pages.error.title')}
        </h1>
        {error && (
          <div className="mt-3 rounded-lg bg-gray-100 p-2 text-xs">
            {toErrorWithMessage(error).message}
          </div>
        )}
        <p className="mt-2 max-w-lg text-base leading-7 text-gray-600">
          {translate('pages.error.subtitle')}
        </p>
        <div className="mt-5 flex items-center justify-center gap-x-6">
          <iframe
            src={`${getStatusURL()}/badge?theme=light`}
            className="items-center rounded-lg bg-gray-50 ring-1 ring-gray-200"
            width="182"
            height="30"
            scrolling="no"
            frameBorder="0"
            title="Status Badge"
          />
        </div>
      </div>
    </motion.div>
  )
}
