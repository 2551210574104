import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  parse,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns'
import { AnimatePresence, MotionConfig, Variants, motion } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMeasure from 'react-use-measure'

interface CalendarProps {
  onDateSelected: (date: Date) => void
  selectableWeekdays?: string[]
}
export default function Calendar(props: CalendarProps) {
  const { t: translate } = useTranslation()

  const [monthString, setMonthString] = useState(format(new Date(), 'yyyy-MM'))
  const [direction, setDirection] = useState<number>()
  const [isAnimating, setIsAnimating] = useState(false)
  const month = parse(monthString, 'yyyy-MM', new Date())

  const [selectedDate, setSelectedDate] = useState<Date>()
  const weekdays = [
    translate('common.weekdays-short.sunday'),
    translate('common.weekdays-short.monday'),
    translate('common.weekdays-short.tuesday'),
    translate('common.weekdays-short.wednesday'),
    translate('common.weekdays-short.thursday'),
    translate('common.weekdays-short.friday'),
    translate('common.weekdays-short.saturday'),
  ]

  function nextMonth() {
    if (isAnimating) return

    const next = addMonths(month, 1)

    setMonthString(format(next, 'yyyy-MM'))
    setDirection(1)
    setIsAnimating(true)
  }

  function previousMonth() {
    if (isAnimating) return

    const previous = subMonths(month, 1)

    setMonthString(format(previous, 'yyyy-MM'))
    setDirection(-1)
    setIsAnimating(true)
  }

  const days = eachDayOfInterval({
    start: startOfWeek(startOfMonth(month)),
    end: endOfWeek(endOfMonth(month)),
  })

  function handleSelectDate(date: Date) {
    setSelectedDate(date)
    props.onDateSelected(date)
  }

  return (
    <MotionConfig transition={transition}>
      <div className="flex items-start text-stone-900">
        <div className="relative w-full max-w-md overflow-hidden rounded-2xl bg-white shadow-xl">
          <div className="py-8">
            <div className="flex flex-col justify-center rounded text-center">
              <ResizablePanel>
                <AnimatePresence
                  mode="popLayout"
                  initial={false}
                  custom={direction}
                  onExitComplete={() => setIsAnimating(false)}
                >
                  <motion.div key={monthString} initial="enter" animate="middle" exit="exit">
                    <header className="relative flex justify-between px-8">
                      <motion.button
                        variants={removeImmediately}
                        className="z-10 rounded-full p-1.5 hover:bg-stone-100"
                        onClick={(e) => {
                          //Otherwise the browser thinks we are submitting the form
                          e.preventDefault()
                          previousMonth()
                        }}
                      >
                        <ChevronLeftIcon className="h-4 w-4" />
                      </motion.button>
                      <motion.p
                        variants={variants}
                        custom={direction}
                        className="absolute inset-0 flex items-center justify-center font-semibold"
                      >
                        {format(month, 'MMMM yyyy')}
                      </motion.p>
                      <motion.button
                        variants={removeImmediately}
                        className="z-10 rounded-full p-1.5 hover:bg-stone-100"
                        onClick={(e) => {
                          //Otherwise the browser thinks we are submitting the form
                          e.preventDefault()
                          nextMonth()
                        }}
                      >
                        <ChevronRightIcon className="h-4 w-4" />
                      </motion.button>
                      <div
                        className="absolute inset-0"
                        style={{
                          backgroundImage:
                            'linear-gradient(to right, white 15%, transparent 30%, transparent 70%, white 85%)',
                        }}
                      />
                    </header>
                    <motion.div
                      variants={removeImmediately}
                      className="mt-6 grid grid-cols-7 gap-y-6 px-8"
                    >
                      {weekdays.map((weekday) => (
                        <div>
                          {props.selectableWeekdays?.includes(weekday) ? (
                            <span className="font-medium text-stone-600 bg-indigo-50 px-2 py-1 rounded-full">
                              {weekday}
                            </span>
                          ) : (
                            <span className="font-medium text-stone-300">{weekday}</span>
                          )}
                        </div>
                      ))}
                    </motion.div>
                    <motion.div
                      variants={variants}
                      custom={direction}
                      className="mt-6 grid grid-cols-7 gap-y-6 px-8"
                    >
                      {days.map((day) => (
                        <>
                          {!props.selectableWeekdays?.includes(weekdays[day.getDay()]) ? (
                            <span className="rounded-full cursor-not-allowed transition-all text-stone-300">
                              {format(day, 'd')}
                            </span>
                          ) : (
                            <span
                              className={`${
                                isSameMonth(day, month)
                                  ? 'rounded-full cursor-pointer transition-all'
                                  : 'text-stone-300 cursor-default'
                              } font-semibold relative`}
                              key={format(day, 'yyyy-MM-dd')}
                              onClick={() => {
                                if (isSameMonth(day, month)) {
                                  handleSelectDate(day)
                                }
                              }}
                            >
                              {format(day, 'd')}
                              {!isAnimating && selectedDate && isSameDay(day, selectedDate) && (
                                <AnimatePresence>
                                  <motion.span
                                    layoutId="selected-date"
                                    animate={{ scale: [0.8, 1.1, 1.2, 1] }}
                                    transition={{
                                      type: 'spring',
                                      bounce: 0.15,
                                      duration: 0.6,
                                    }}
                                    variants={removeImmediately}
                                    className={`${
                                      !isSameMonth(day, month) ? 'bg-indigo-50' : 'bg-indigo-100'
                                    } absolute w-8 h-8 opacity-100 -z-10 bg-green-200 rounded-full left-0 right-0 top-0 bottom-0 m-auto`}
                                  />
                                </AnimatePresence>
                              )}
                            </span>
                          )}
                        </>
                      ))}
                    </motion.div>
                  </motion.div>
                </AnimatePresence>
              </ResizablePanel>
            </div>
          </div>
        </div>
      </div>
    </MotionConfig>
  )
}

export function ResizablePanel({ children }: any) {
  const [ref, bounds] = useMeasure()

  return (
    <motion.div animate={{ height: bounds.height > 0 ? bounds.height : (null as never) }}>
      <div ref={ref}>{children}</div>
    </motion.div>
  )
}

export function ResizableWidth({ children }: any) {
  const [ref, bounds] = useMeasure()

  return (
    <motion.div animate={{ width: bounds.width > 0 ? bounds.width : (null as never) }}>
      <div ref={ref}>{children}</div>
    </motion.div>
  )
}

const transition = { type: 'spring', bounce: 0, duration: 0.25 }

const variants = {
  enter: (direction: any) => {
    return { x: `${100 * direction}%`, opacity: 0 }
  },
  middle: { x: '0%', opacity: 1 },
  exit: (direction: any) => {
    return { x: `${-100 * direction}%`, opacity: 0 }
  },
}

const removeImmediately: Variants = {
  exit: { visibility: 'hidden' },
}
