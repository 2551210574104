import { qoursesApi } from '@/api/qourses.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import { SwitchWithLabels } from '@/pages/UserSettings.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { Input } from '@/shadcn/components/ui/input.tsx'
import { Label } from '@/shadcn/components/ui/label.tsx'
import { ToastVariant, minDelay, sendGenericErrorNotification, sendNotification } from '@/utils.tsx'
import { Cog, Loader2 } from 'lucide-react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrganizationEntity } from '../../../qourses-api-client'

export default function UpdateOrganizationTrialModal({
  organization,
}: {
  organization: OrganizationEntity
}) {
  const [isLoading, setLoading] = useState(false)

  const [trialAvailable, setTrialAvailable] = useState(organization.trialAvailable)
  const [trialUntil, setTrialUntil] = useState('')

  const { t: translate } = useTranslation()

  useEffect(() => {
    if (organization.trialUntilOverride) {
      setTrialUntil(DateTime.fromISO(organization.trialUntilOverride).toISO().slice(0, 10))
    } else {
      setTrialUntil('')
    }
  }, [])

  const handleUpdateOrganizationTrialSettings = async () => {
    try {
      setLoading(true)
      await minDelay(
        qoursesApi.organizationAdmin.organizationAdminControllerPatchOrganization(organization.id, {
          trialAvailable: trialAvailable,
          trialUntilOverride: DateTime.fromISO(trialUntil).toISO(),
        }),
        1000,
      )
      sendNotification(
        "Organization's updated",
        "The organization's trial settings have been updated",
        ToastVariant.Success,
      )
      popAllModals()
    } catch (e) {
      console.error(e)
      sendGenericErrorNotification()
      sendNotification(
        'Updated Failed',
        "The organization's trial settings could not be updated",
        ToastVariant.Error,
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dynamic.Content className="p-6 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogTitle className="mt-6 sm:mt-2">Update Organization Trial</DialogTitle>
        <DialogDescription>Update the organizations trial eligibility & end date</DialogDescription>
      </DialogHeader>
      <div className="mb-8 space-y-8">
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <SwitchWithLabels
            title={'Trial Available (Override)'}
            subtitle={'Whether or not the organization will be offered a trial on plan selection'}
            enabled={trialAvailable}
            disabled={false}
            loading={isLoading}
            onChange={() => {
              setTrialAvailable(!trialAvailable)
            }}
          />
        </div>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="name" className="mb-1">
            Trial Available Until (Override)
          </Label>
          <Input
            type={'date'}
            value={DateTime?.fromISO(trialUntil)?.toISO()?.slice(0, 10)}
            onChange={(e) => {
              setTrialUntil(e.target.value)
            }}
          />
          <Button
            variant="secondary"
            disabled={isLoading}
            onClick={() => {
              setTrialUntil(DateTime.now().plus({ days: 30 }).toISO().slice(0, 10))
            }}
          >
            +30 Days
          </Button>
          <Button
            variant="secondary"
            disabled={isLoading}
            onClick={() => {
              setTrialUntil(DateTime.now().plus({ days: 60 }).toISO().slice(0, 10))
            }}
          >
            +60 Days
          </Button>
          <Button
            variant="secondary"
            disabled={isLoading}
            onClick={() => {
              setTrialUntil(DateTime.now().plus({ days: 90 }).toISO().slice(0, 10))
            }}
          >
            +90 Days
          </Button>
        </div>
      </div>
      <Button variant="indigo" disabled={isLoading} onClick={handleUpdateOrganizationTrialSettings}>
        {isLoading ? (
          <>
            {translate('common.loading')}
            <Loader2 className="ml-2 h-4 w-4 animate-spin" />
          </>
        ) : (
          <>
            Update Trial Settings
            <Cog className="ml-2 h-4 w-4" />
          </>
        )}
      </Button>
    </Dynamic.Content>
  )
}
