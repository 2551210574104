import { useQuery } from '@tanstack/react-query'
import { UserNotificationPreferencesEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetUserNotificationPreferences(): {
  userNotificationPreferences: UserNotificationPreferencesEntity | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: userNotificationPreferences,
    isLoading,
    isError,
    refetch,
  } = useQuery(['userNotificationPreferences'], async () => {
    return qoursesApi.notification.notificationControllerGetUserNotificationPreferences()
  })

  return { userNotificationPreferences, isLoading, isError, refetch }
}
