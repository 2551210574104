/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CapabilityStatusDto = {
    capabilityStatus: CapabilityStatusDto.capabilityStatus;
    requirementsDue: boolean;
};
export namespace CapabilityStatusDto {
    export enum capabilityStatus {
        ACTIVE = 'active',
        DISABLED = 'disabled',
        INACTIVE = 'inactive',
        PENDING = 'pending',
        UNREQUESTED = 'unrequested',
    }
}

